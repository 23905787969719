.QuestionView {
	padding-bottom: 10px;
	border-bottom: 1px solid #eee;
}

.QuestionView .red {
	background-color: #fbb;
}

.QuestionView .vexpand {
	padding-top: 5px;
	padding-bottom: 5px;
}

.QuestionView > .vexpand > h3 {
	margin-top: 10px !important;
}

.QuestionView > .vexpand > h3:first-child {
	font-size: var(--question__font-size);
}

.QuestionView > .vexpand > .question__body {
	padding-inline: var(--question__body__padding-inline);
}
