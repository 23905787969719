.QuestionMatrixView td.col-cell {
	text-align: center;
	vertical-align: middle;
}

.QuestionMatrixView td.col-cell label.col-label {
	font-size: var(--choice__font-size);
}

.QuestionMatrixView td.col-cell label.col-label input {
	margin-top: 0;
	vertical-align: middle;
}

.QuestionMatrixView td.col-cell label.col-label input[type="checkbox"] {
	width: var(--checkbox__width);
	height: var(--checkbox__height);
}

.QuestionMatrixView td.col-cell label.col-label span {
	position: relative;
	top: 2px;
}
