.QuestionRatingView > table > tbody > tr > td > i.checked:not(.hovered) {
	color: #ffcc00;
}

.QuestionRatingView > table > tbody > tr > td > i.hovered {
	color: #ff8000;
}

.QuestionRatingView > table > tbody > tr > td:first-child {
	width: 30%;
}

.QuestionRatingView > table > tbody > tr > td:nth-child(2) {
	text-align: center;
}
