.QuestionScoreView > .nps-question {
	display: flex;
	justify-content: space-around;
}

.QuestionScoreView > .nps-question > label {
	text-align: center;
}

.QuestionScoreView > .nps-question > label > span {
	display: block;
}
