:root {
	/* Page */
	--page__font-size: 20px;

	/* Question */
	--question__font-size: 16px;
	--question__body__padding-inline: 20px;

	/* Question choice */
	--choice__group__font-size: 14px;
	--choice__font-size: 14px;

	/* Common */
	--checkbox__width: 14px;
	--checkbox__height: 14px;
}

.App {
}

.App .small-offset-top {
	margin-top: 1em;
}

.App .clickable {
	cursor: pointer;
}
