.QuestionChoiceView .shift-right {
	margin-left: 2em;
}

.QuestionChoiceView .bluebg {
	background-color: #d9edf7;
}

.QuestionChoiceView .group {
	color: grey;
	font-size: var(--choice__group__font-size);
}

.QuestionChoiceView label.choice-label {
	width: 100%;
	padding-block: 3px;
	font-size: var(--choice__font-size);
	font-weight: normal;
}

.QuestionChoiceView label.choice-label > input {
	margin-top: 0;
	vertical-align: middle;
}

.QuestionChoiceView label.choice-label > input[type="checkbox"] {
	width: var(--checkbox__width);
	height: var(--checkbox__height);
}

.QuestionChoiceView label.choice-label > span {
	position: relative;
	top: 2px;
}